import React from "react"
import { Container } from "reactstrap"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import { Banner, TeamCollection } from "@igloonet-web/shared-ui"
import { useActiveEmployees } from "../../hooks/use-active-employees"
import ProfileImage from "../../components/profile-image"

export default function Tym() {
  const employees = useActiveEmployees()

  return (
    <Layout>
      <Helmet>
        <title>Tučňáci na hostingu | igloonet</title>
        <meta
          name="description"
          content="Kdo se postará o vaše servery a weby? Seznamte se s úžasným hostingovým týmem igloonetu."
        />
      </Helmet>
      <Banner>
        <h1>Tučňáci na hostingu</h1>
      </Banner>

      <Container className="text-center">
        <TeamCollection
          imageTag={ProfileImage}
          teamMembers={employees.filter((e) => e.division === "hosting")}
        />
      </Container>
    </Layout>
  )
}
